<template>
  <div v-if="mostrarInvoices && invoices && invoices.length" class="grid-widget dash-business-invoices">
    <div class="font-bold text-negative" v-if="financialBlock">
      Você tem {{ invoices.length }} pagamento(s) em aberto, por isto, seu acesso ao sistema está suspenso. Pague o mais breve possível e evite a suspensão do seu site.
    </div>
    <div class="font-bold" v-else>Você tem {{ invoices.length }} pagamento(s) em aberto</div>
    <div class="dash-invoices">
      <table>
        <thead>
        <tr>
          <th>Vencimento</th>
          <th>Valor</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="invoice in invoices" :key="invoice.id">
          <td>{{invoice.dataVencimento|formatDate('dd/MM/yyyy')}}</td>
          <td>R$ {{invoice.valor|moeda}}</td>
          <td><a @click="pagar(invoice)">Pagar</a></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import {invoices} from "@/domain/business/services"
import invoiceWindow from "./window/invoiceWindow"
import {erpDefs} from "@/definitions"
import {differenceInHours, parseISO} from "date-fns";

export default {
  name: "BusinessInvoices",
  data () {
    return {
      // invoices: null
    }
  },
  computed: {
    mostrarInvoices () {
      const hasPermission = this.cracl('ROLE_FINANCEIRO', null)
      const atraso = (d) => {
        return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
      }
      let dueInvoices = 0
      this.invoices && this.invoices.length && this.invoices.map(invoice => {
        if (invoice.dataVencimento && atraso(invoice.dataVencimento.date) > 3) {
          erpDefs.dueInvoices.push(invoice)
          erpDefs.totalDue = erpDefs.totalDue + Number(invoice.valor)
          dueInvoices++
        }
      })
      return hasPermission || dueInvoices > 0
    },
    invoices () {
      return erpDefs.invoices
    },
    financialBlock () {
      return erpDefs.financialBlock
    }
  },
  mounted() {
    // this.load()
  },
  watch: {
    'financialBlock' (v) {
      if (v) {
        document.getElementById('uloc-app').classList.add('hide')
        document.body.append(this.$el)
      }
    }
  },
  methods: {
    /*load () {
      invoices()
          .then(response => {
            console.log(response)
            this.invoices = response.data.result
          })
          .catch(error => {
            console.error(error)
          })
    },*/
    invoiceWindow,
    pagar (invoice) {
      this.$router.push({
        name: 'suporte.cliente.faturas.pagarFatura',
        params: {id: invoice.id}
      })
      //this.invoiceWindow(invoice.id)
    }
  }
}
</script>
